import {
  DatePicker,
  Select,
  Table,
  Typography,
  Breadcrumb,
  Button,
  Form,
  Row,
  Col,
} from "antd";
import {
  actionGetAgentYearlyReportAll,
  clearReportData,
} from "../../store/actions/reportAction";
import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, ExportOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { actionGetUsersAllData } from "../../store/actions/userAction";

const AgentYearlyReport = ({
  agentYearlyReportLoader,
  agentYearlyReportData,
  actionGetAgentYearlyReportAll,
  agentExportYearlyReportData,
  agentYearlyReportCount,
  allUsersData,
  allUsersLoader,
  actionGetUsersAllData,
  clearReportData,
}) => {
  const [current1, setCurrent1] = useState(1);
  const [year, setYear] = useState("");
  const [user, setUser] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    actionGetUsersAllData(0, 10);
    actionGetAgentYearlyReportAll(0, 10);
  }, []);

  const Navigate = useNavigate();

  useMemo(() => {}, [agentYearlyReportData]);

  const columns1 = [
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      fixed: "left",
      width: 200,
    },
    // Add columns for each month
    {
      title: "Jan",
      dataIndex: "january",
      key: "january",
      width: 100,
    },
    {
      title: "Feb",
      dataIndex: "february",
      key: "february",
      width: 100,
    },
    {
      title: "Mar",
      dataIndex: "march",
      key: "march",
      width: 100,
    },
    {
      title: "Apr",
      dataIndex: "april",
      key: "april",
      width: 100,
    },
    {
      title: "May",
      dataIndex: "may",
      key: "may",
      width: 100,
    },
    {
      title: "Jun",
      dataIndex: "june",
      key: "june",
      width: 100,
    },
    {
      title: "Jul",
      dataIndex: "july",
      key: "july",
      width: 100,
    },
    {
      title: "Aug",
      dataIndex: "august",
      key: "august",
      width: 100,
    },
    {
      title: "Sep",
      dataIndex: "september",
      key: "september",
      width: 100,
    },
    {
      title: "Oct",
      dataIndex: "october",
      key: "october",
      width: 100,
    },
    {
      title: "Nov",
      dataIndex: "november",
      key: "november",
      width: 100,
    },
    {
      title: "Dec",
      dataIndex: "december",
      key: "december",
      width: 100,
    },
    // Add Total column
    {
      title: "Total",
      dataIndex: "total",
      fixed: "right",
      key: "total",
      width: 150,
    },
  ];

  const data1 = [];
  agentYearlyReportData &&
    agentYearlyReportData.length > 0 &&
    agentYearlyReportData.map((e, index) => {
      let parts = e.total.split(":");
      let value = parseFloat(parts[1]); // Parsing the second part as a floating-point number
      let updatedData = "";
      if (!isNaN(value)) {
        updatedData = e.total;
      } else {
        // parts;
        parts[1] = "00";
        updatedData = parts.join(":");
        console.log("Updated data:", updatedData);
      }

      data1.push({
        key: index,
        agent: e.name,
        january: e.data.January !== "00:00" ? e.data.January : "-",
        february: e.data.February !== "00:00" ? e.data.February : "-",
        march: e.data.March !== "00:00" ? e.data.March : "-",
        april: e.data.April !== "00:00" ? e.data.April : "-",
        may: e.data.May !== "00:00" ? e.data.May : "-",
        june: e.data.June !== "00:00" ? e.data.June : "-",
        july: e.data.July !== "00:00" ? e.data.July : "-",
        august: e.data.August !== "00:00" ? e.data.August : "-",
        september: e.data.September !== "00:00" ? e.data.September : "-",
        october: e.data.October !== "00:00" ? e.data.October : "-",
        november: e.data.November !== "00:00" ? e.data.November : "-",
        december: e.data.December !== "00:00" ? e.data.December : "-",
        // Calculate total

        total: updatedData !== "00:00" ? updatedData : "00:00",
      });
    });

  const handleChange = (e, val) => {
    setYear(val);
    setCurrent1(1);
  };

  const onClickPaginationNumberForAdmin = (e, t, sorter) => {
    actionGetAgentYearlyReportAll(e.current - 1, e.pageSize, year);
    setCurrent1(e.current);
  };
  const breadcrumbItems = [
    {
      key: "back",
      title: (
        <>
          <ArrowLeftOutlined style={{ cursor: "pointer" }} />
          <span
            style={{
              cursor: "pointer",
              color: "rgba(0, 0, 0, 0.88)",
              fontWeight: "600",
              fontSize: 18,
            }}
          >
            Go Back
          </span>
        </>
      ),
      onClick: () => Navigate(-1),
    },
  ];

  useEffect(() => {
    if (agentExportYearlyReportData && agentExportYearlyReportData.length > 0) {
      const data =
        agentExportYearlyReportData &&
        agentExportYearlyReportData.map((data) => ({
          Agent: data && data.name ? data.name : "-",
          // "Jan":
          //     data.data.January ? data.data.January
          //         : "-",
          Jan: data.data.January !== "00:00" ? data.data.January : "-",
          Feb: data.data.February !== "00:00" ? data.data.February : "-",
          Mar: data.data.March !== "00:00" ? data.data.March : "-",
          Apr: data.data.April !== "00:00" ? data.data.April : "-",
          May: data.data.May !== "00:00" ? data.data.May : "-",
          Jun: data.data.June !== "00:00" ? data.data.June : "-",
          Jul: data.data.July !== "00:00" ? data.data.July : "-",
          Aug: data.data.August !== "00:00" ? data.data.August : "-",
          Sep: data.data.September !== "00:00" ? data.data.September : "-",
          Oct: data.data.October !== "00:00" ? data.data.October : "-",
          Nov: data.data.November !== "00:00" ? data.data.November : "-",
          Dec: data.data.December !== "00:00" ? data.data.December : "-",
          Total: data.total ? data.total : "-",
        }));
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
      // Generate the Excel file and initiate the download
      XLSX.writeFile(workbook, "SAP_MonthlyAgentProductivity_Report.xlsx");
      clearReportData();
    }
  }, [agentExportYearlyReportData]);
  const exportLogToExcel = () => {
    try {
      actionGetAgentYearlyReportAll(0, 10, year, user, "YES");
    } catch (err) {
      console.log(err);
    }
  };

  const agentData = [];
  allUsersData?.map((data, index) => {
    if (data.roles === "AGENT") {
      agentData.push({
        key: index,
        label: data.first_name + " " + data.last_name,
        value: data.first_name + " " + data.last_name,
        // value: data.id,
      });
    }
  });

  const onChange = (val) => {
    setUser(val);
    setCurrent1(1);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const handleFinish = (value) => {
    setCurrent1(1);
    actionGetAgentYearlyReportAll(0, 10, year, value.user);
  };

  return (
    <>
      <Breadcrumb items={breadcrumbItems} className="mb-3" />

      <div>
        {" "}
        <p className=" text-[24px] font-semibold">
          Monthly Agent Productivity Overview
        </p>
        <Form onFinish={handleFinish} className="mt-8" form={form}>
          <Row gutter={[16, 16]}>
            <Col span={5}>
              <Form.Item className="mb-4" name="user">
                <Select
                  onClear={() => setUser("")}
                  style={{ padding: "6px 0" }}
                  className="width py-[7px] h-full rounded border border-true w-full"
                  showSearch
                  allowClear
                  placeholder="Select Agent"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={agentData}
                  loading={allUsersLoader}
                  disabled={allUsersLoader}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item className="mb-4" name="year">
                <DatePicker
                  disabledDate={(current) => current && current.year() < 2023}
                  onChange={handleChange}
                  className="w-full  mb-4 border border-true"
                  picker="year"
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item>
                <Button
                  className="min-h-[42px] outline-none focus:shadow-none focus-visible:outline-0 focus-visible:outline-offset-0"
                  type="primary"
                  htmlType="submit"
                >
                  Search
                </Button>
              </Form.Item>
            </Col>
            <div className="ml-auto mr-3">
              <Button
                className="min-h-[42px] outline-none focus:shadow-none focus-visible:outline-0 focus-visible:outline-offset-0 flex items-center justify-center"
                onClick={exportLogToExcel}
              >
                <ExportOutlined />
                Export
              </Button>
            </div>
          </Row>
        </Form>
        <Table
          bordered
          className="user-table"
          sticky={true}
          size="small"
          pagination={{
            current: current1,
            total: agentYearlyReportCount,
            hideOnSinglePage: true,
          }}
          columns={columns1}
          dataSource={data1}
          loading={agentYearlyReportLoader}
          onChange={(e) => onClickPaginationNumberForAdmin(e)}
          scroll={{
            x: "max-content",
          }}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfileData: state.auth.userProfileData,
    agentYearlyReportLoader: state.report.agentYearlyReportLoader,
    agentYearlyReportData: state.report.agentYearlyReportData,
    agentYearlyReportCount: state.report.agentYearlyReportCount,
    agentExportYearlyReportData: state.report.agentExportYearlyReportData,
    allUsersData: state.users.allUsersData,
    allUsersLoader: state.users.allUsersLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetAgentYearlyReportAll,
  actionGetUsersAllData,
  clearReportData,
})(AgentYearlyReport);
