import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Row,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import logo from "../../assets/img/comman/logo.png";
// import UserOutlined
import { MailOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actionLogin } from "../../store/actions/authAction";
const { Title } = Typography;

const Login = (props) => {
  const Navigate = useNavigate();
  const { actionLogin, LoginLoader } = props;
  const [form] = Form.useForm();
  const rushirDecorEmail = localStorage.getItem("RushirDecorEmail");
  const rushirDecorPassword = localStorage.getItem("RushirDecorPassowrd");

  useEffect(() => {
    if (localStorage.rushirBackendJwtToken) {
      Navigate(`/`);
    }

    rushirDecorEmail &&
      rushirDecorPassword &&
      form.setFieldsValue({
        email: rushirDecorEmail,
        password: rushirDecorPassword,
      });
  }, []);

  const onFinish = (values) => {
    actionLogin(values, Navigate);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <Card
        className="login-card"
        // title={<Image src={logo} preview={false} />}
        style={{
          width: 450,
          marginBottom: "100px",
        }}
      >
        <div className="logo text-center">
          <Image src={logo} preview={false} alt="logo" />
          <h2 className="text-2xl	pb-10 pt-3  font-bold">Log In</h2>
        </div>
        <Form
          form={form}
          name="basic"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
        >
          <Row align="center" className="row-card" style={{ paddingBottom: 0 }}>
            <Col span="24">
              <Form.Item
                name="email"
                rules={[
                  {
                    /* type: "text", */
                    required: true,
                    message: "Please input your email or Username!",
                  },
                ]}
              >
                <Input
                  placeholder="Email Or Username"
                  suffix={<MailOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
            </Col>
            {/* <Link to='/forgot-password'>ForgotPassword </Link> */}
            <Col span={24}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </Col>
            {/* <Col span={12} align="left">
              <Form.Item name="remember">
                <Checkbox checked>Remember me</Checkbox>
              </Form.Item>
            </Col> */}
            <Col span={12} align="left">
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
            </Col>
            <Col span={12} align="right">
              <Form.Item>
                <Button block loading={LoginLoader} htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
            <Col span={24} />
            <Col span={24} align="middle">
              <Form.Item>
                <Link
                  style={{ color: "#265BA6", fontWeight: 600 }}
                  className="link-color"
                  to="/forgot-password"
                >
                  Forgot password?
                </Link>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    LoginLoader: state.auth.LoginLoader,
  };
};

export default connect(mapStateToProps, { actionLogin })(Login);
